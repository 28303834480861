'use client'
import Image from 'next/image'
import Button from 'components/global/Button'
import Link from 'next/link'

import { event } from 'utils/gtm'

export default function BannerHome({ className, section }) {
    const handleClick = () => {
        event({
            event: 'gaEvent',
            location: section,
            event_name: 'banner',
            detail: 'entrega rapida - ver todas las ofertas',
        })
    }

    const banner = (
        <>
            {/*
        <div className="hidden md:block col-span-1 md:col-span-2 xl:col-span-3 xl:col-span-4">
            <Image src="/assets/justfordevelop/banner-comercial-desktop.jpg" width="1184" height="277" layout="responsive" alt="" />
        </div>
        <div className="block sm:hidden">
            <Image src="/assets/justfordevelop/banner-comercial-mobile.png" width="311" height="388" layout="responsive" alt="" />
        </div>
        */}
            <div className="overlow-hidden relative mx-auto hidden h-[280px] max-w-[1440px] xl:block">
                <Link href="/renting/?entregaRapida=true" passHref onClick={handleClick}>
                    <Image
                        src="/assets/content/blocks/Head-escritorio-entrega-rapida.jpg"
                        className=""
                        alt=""
                        fill
                        sizes="100vw"
                    />
                </Link>
                {/*
                <Image
                    src="/assets/justfordevelop/Rectangle 30.png"
                    className="absolute left-0 top-0 h-[280px]"
                    alt=""
                    fill
                    sizes="100vw"
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'left',
                    }}
                />
                <div className="absolute z-30 w-1/2 space-y-4 pl-[110px] pr-[50px] pt-[57px] text-white">
                    <div className="text-3xl font-semibold">Vehículos de entrega rápida.</div>
                    <div className="text-lg">
                        ¿Lo quieres? Lo tienes. Elige una de nuestras ofertas y muy pronto podrás disfrutar de tu
                        renting LeasePlan.
                    </div>
                    <div>
                        <Link href="/renting/?entregaRapida=true" passHref onClick={handleClick}>
                            <Button>VER TODAS LAS OFERTAS</Button>
                        </Link>
                    </div>
                </div>
                 */}
            </div>
            <div className="block xl:hidden">
                <div className="degPrimary space-y-8 p-6 text-white">
                    <div className="text-3xl font-semibold leading-tight ">Vehículos de entrega rápida.</div>
                    <div className="text-lg leading-snug">
                        ¿Lo quieres? Lo tienes. Encuentra la oferta perfecta para ti y disfruta de tu nuevo coche.
                    </div>
                    <div className="pt-2 text-center">
                        <Link href="/renting/?entregaRapida=true" passHref onClick={handleClick}>
                            <Button className="w-full lg:w-auto">VER TODAS LAS OFERTAS</Button>
                        </Link>
                    </div>
                </div>
                <div className="relative h-[280px] overflow-hidden">
                    <Image
                        src="/assets/content/blocks/banner-entregarapida.jpg"
                        fill
                        sizes="100vw"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'bottom',
                        }}
                    />
                </div>
            </div>
        </>
    )

    return <div className={`${className} relative`}>{banner}</div>
}
